import { useAuth } from 'react-oidc-context';
import { useIntl } from 'react-intl';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import LogoutIcon from '@mui/icons-material/Logout';

import { useUserInfo } from '../../contexts/UserInfoContext';

const Header = () => {
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const userInfo = useUserInfo();

  const handleLogout = () => auth.signoutRedirect();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundImage:
          'linear-gradient(to right, #0f3c5d, #175470, #1c5f79, #288a97, #30a1a9, #34aeb3, #35b0b5)',
      }}
    >
      <Toolbar variant="dense">
        <Typography variant="h5" component="h1" sx={{ flexGrow: 1 }}>
          {formatMessage({ id: 'header.title' })}
        </Typography>
        
        <Typography sx={{ mr: 1, fontWeight: 'bold' }}>
          <span>{auth.user.profile.displayName || auth.user.profile.preferred_username}</span>
          {userInfo?.sztsz && <span> ({userInfo.sztsz})</span>}
        </Typography>
        <IconButton size="large" color="inherit" onClick={handleLogout}>
          <Tooltip title={formatMessage({ id: 'header.logout' })}>
            <LogoutIcon />
          </Tooltip>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
