import { useState, useEffect, useMemo, useCallback } from 'react';

import { useAuth } from 'react-oidc-context';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import UploadIcon from '@mui/icons-material/Upload';
import ActionMenuIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { inventoryResource } from '../../rest';

import { useUserInfo } from '../../contexts/UserInfoContext';

import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import StyledAccordion from '../../components/StyledAccordion';
import InventoryAreaSignatureTable from '../../components/InventoryAreaSignatureTable';
import InventoryTicketTable from '../../components/InventoryTicketTable';
import FileUploadDialog from '../../components/FileUploadDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EmptyTable from '../../components/EmptyTable';

const StyledTablePagination = styled(TablePagination)(() => ({
  '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
    minHeight: '2.5rem',
    paddingRight: 0,
  },
  '&.MuiTablePagination-root p': {
    margin: 0,
  },
}));

const InventoryAreaList = () => {
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const userInfo = useUserInfo();

  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(null);
  const [inventoryAreas, setInventoryAreas] = useState({});
  const [inventoryTicketStatistics, setInventoryTicketStatistics] = useState({});
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchTermValue, setSearchTermValue] = useState('');
  const [pageNumberValue, setPageNumberValue] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(10);
  const [orderFieldValue, setOrderFieldValue] = useState('LOCATION_CODE');
  const [orderDirectionValue, setOrderDirectionValue] = useState('ASC');
  const [uploadDialog, setUploadDialog] = useState({ open: false });
  const [adminMenuAnchorElement, setAdminMenuAnchorElement] = useState(null);
  const adminMenuOpen = Boolean(adminMenuAnchorElement);

  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    loading: false,
    error: false,
  });

  const hasAdminRole = useMemo(
    () => auth.user.profile.realm_access.roles.includes('pita-admin'),
    [auth]
  );

  const hasSupervisorRole = useMemo(
    () => auth.user.profile.realm_access.roles.includes('pita-supervisor'),
    [auth]
  );

  const searchInventoryAreas = useCallback(
    async (searchTerm, pageNumber, pageSize, orderField, orderDirection) => {
      try {
        setLoading(true);
        setErrorCode(null);

        const fetchedInventoryAreas = await inventoryResource.listInventoryTickets(
          searchTerm || undefined,
          pageNumber,
          pageSize,
          orderField,
          orderDirection
        );
        setInventoryAreas(fetchedInventoryAreas || {});

        const fentchedInventoryTicketStatistics =
          await inventoryResource.getInventoryTicketStatistics();
        setInventoryTicketStatistics(fentchedInventoryTicketStatistics);
      } catch (error) {
        setInventoryAreas({});
        setErrorCode(error.response.status);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    searchInventoryAreas(
      searchTermValue,
      pageNumberValue,
      pageSizeValue,
      orderFieldValue,
      orderDirectionValue
    );
  }, [
    searchInventoryAreas,
    searchTermValue,
    pageNumberValue,
    pageSizeValue,
    orderFieldValue,
    orderDirectionValue,
  ]);

  const handleRefresh = () => {
    setSearchTermValue(searchInputValue);
    searchInventoryAreas(
      searchTermValue,
      pageNumberValue,
      pageSizeValue,
      orderFieldValue,
      orderDirectionValue
    );
  };

  const handleSearch = () => {
    setSearchTermValue(searchInputValue);
    setPageNumberValue(0);
  };

  const handleSearchInputValueChange = event => setSearchInputValue(event.target.value);

  const handleSearchKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handlePageNumberChange = (_, newPage) => {
    setPageNumberValue(newPage);
  };

  const handlePageSizeChange = event => {
    setPageSizeValue(parseInt(event.target.value, 10));
    setPageNumberValue(0);
  };

  const handleOrderFieldChange = event => {
    setOrderFieldValue(event.target.value);
    setPageNumberValue(0);
  };

  const handleOrderDirectionChange = () => {
    setOrderDirectionValue(orderDirectionValue === 'ASC' ? 'DESC' : 'ASC');
    setPageNumberValue(0);
  };

  const openExternalUrl = url => window.open(url, '_blank', 'noreferrer');

  const generateInventoryAreaSignatureFile = inventoryAreaId =>
    inventoryResource.generateInventoryAreaSignature(inventoryAreaId);

  const handleInventoryAreaSignatureUploadButtonClick = inventoryAreaId =>
    setUploadDialog({ open: true, type: 'signature', data: inventoryAreaId });

  const downloadInventoryAreaSignature = (inventoryAreaId, inventoryAreaSignatureId) =>
    inventoryResource.downloadInventoryAreaSignature(inventoryAreaId, inventoryAreaSignatureId);

  const deleteInventoryAreaSignature = (inventoryAreaId, inventoryAreaSignatureId) => {
    setConfirmationDialog(prev => ({
      ...prev,
      open: true,
      message: formatMessage({
        id: 'inventory-area.confirmation.delete-inventory-area-signature-message',
      }),
      onConfirm: async () => {
        try {
          setConfirmationDialog(previous => ({ ...previous, error: false, loading: true }));

          await inventoryResource.deleteInventoryAreaSignature(
            inventoryAreaId,
            inventoryAreaSignatureId
          );

          setConfirmationDialog(previous => ({ ...previous, loading: false, open: false }));

          // Remove inventory area signature.
          const previousInventoryAreas = [...inventoryAreas.items];

          const currentInventoryArea = previousInventoryAreas.find(
            inventoryArea => inventoryArea.id === inventoryAreaId
          );

          const currentInventoryAreaSignatureIndex = currentInventoryArea.signatures.findIndex(
            signature => signature.id === inventoryAreaSignatureId
          );
          currentInventoryArea.signatures.splice(currentInventoryAreaSignatureIndex, 1);

          if (
            !currentInventoryArea?.signatures?.length &&
            !currentInventoryArea?.inventoryTickets?.length
          ) {
            // If there is no signature and inventory ticket left in the inventory area, than search again, because inventory area was deleted as well.
            // To delete it in client side would be more difficult because of the pagination.
            searchInventoryAreas(
              searchTermValue,
              pageNumberValue,
              pageSizeValue,
              orderFieldValue,
              orderDirectionValue
            );
          } else {
            setInventoryAreas(previous => ({ ...previous, items: previousInventoryAreas }));
          }
        } catch (error) {
          setConfirmationDialog(previous => ({ ...previous, loading: false, error: true }));
        }
      },
    }));
  };

  const downloadInventoryTicket = (_, inventoryTicketNumber) =>
    inventoryResource.downloadInventoryTicket(inventoryTicketNumber);

  const deleteInventoryTicket = (inventoryAreaId, inventoryTicketNumber) =>
    setConfirmationDialog(prev => ({
      ...prev,
      open: true,
      message: formatMessage(
        { id: 'inventory-area.confirmation.delete-inventory-ticket-message' },
        { inventoryTicketNumber }
      ),
      onConfirm: async () => {
        try {
          setConfirmationDialog(previous => ({ ...previous, error: false, loading: true }));

          await inventoryResource.deleteInventoryTicket(inventoryTicketNumber);

          setConfirmationDialog(previous => ({ ...previous, loading: false, open: false }));

          // Remove inventory ticket.
          const previousInventoryAreas = [...inventoryAreas.items];

          const currentInventoryArea = previousInventoryAreas.find(
            inventoryArea => inventoryArea.id === inventoryAreaId
          );

          const currentInventoryTicketIndex = currentInventoryArea.inventoryTickets.findIndex(
            inventoryTicket => inventoryTicket.inventoryTicketNumber === inventoryTicketNumber
          );
          currentInventoryArea.inventoryTickets.splice(currentInventoryTicketIndex, 1);

          if (
            !currentInventoryArea?.signatures?.length &&
            !currentInventoryArea?.inventoryTickets?.length
          ) {
            // If there is no signature and inventory ticket left in the inventory area, than search again, because inventory area was deleted as well.
            // To delete it in client side would be more difficult because of the pagination.
            searchInventoryAreas(
              searchTermValue,
              pageNumberValue,
              pageSizeValue,
              orderFieldValue,
              orderDirectionValue
            );
          } else {
            setInventoryAreas(previous => ({ ...previous, items: previousInventoryAreas }));
            setInventoryTicketStatistics(previous => ({
              ...previous,
              ticketCount: previous.ticketCount - 1,
            }));
          }
        } catch (error) {
          setConfirmationDialog(previous => ({ ...previous, loading: false, error: true }));
        }
      },
    }));

  const handleInventoryTicketUploadButtonClick = () =>
    setUploadDialog({ open: true, type: 'inventory-ticket' });

  const handleOpenAdminMenu = event => {
    event.stopPropagation();
    setAdminMenuAnchorElement(event.currentTarget);
  };

  const handleCloseAdminMenu = (event, callback) => {
    event.stopPropagation();

    if (typeof callback === 'function') {
      callback();
    }

    setAdminMenuAnchorElement(null);
  };

  const handleImportEvaulationLinksButtonClick = () =>
    setUploadDialog({ open: true, type: 'evaulation-links' });

  const handleCSVExportButtonClick = () => inventoryResource.downloadCSVExport();

  const handleUploadDialogClose = (_, reason, atLeastOneSuccessfulUpload) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }

    setUploadDialog({ open: false });

    if (atLeastOneSuccessfulUpload) {
      searchInventoryAreas(
        searchTermValue,
        pageNumberValue,
        pageSizeValue,
        orderFieldValue,
        orderDirectionValue
      );
    }
  };

  const handleConfirmationDialogClose = (_, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return;
    }

    setConfirmationDialog({ ...confirmationDialog, open: false, loading: false, error: false });
  };

  return (
    <>
      <FileUploadDialog uploadDialog={uploadDialog} onClose={handleUploadDialogClose} />

      <ConfirmationDialog
        open={confirmationDialog.open}
        message={confirmationDialog.message}
        loading={confirmationDialog.loading}
        error={confirmationDialog.error}
        onConfirm={confirmationDialog.onConfirm}
        onClose={handleConfirmationDialogClose}
      />

      <PageContainer sx={{ minHeight: 'calc(100vh - 48px)', py: 4 }}>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item lg={7} md={6} sm={12}>
            <PageTitle>{formatMessage({ id: 'inventory-area.title' })}</PageTitle>
            {inventoryTicketStatistics && (
              <Typography sx={{ fontSize: '0.875rem' }}>
                {formatMessage(
                  { id: 'inventory-area.subtitle' },
                  {
                    inventoryAreaCount: inventoryTicketStatistics.areaCount,
                    inventoryTicketCount: inventoryTicketStatistics.ticketCount,
                  }
                )}
              </Typography>
            )}
          </Grid>
          {(hasAdminRole || hasSupervisorRole) && (
            <Grid
              item
              lg={5}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: { sm: 'flex-start', md: 'flex-end' },
                alignItems: 'center',
              }}
            >
              {hasAdminRole && (
                <Button
                  variant="contained"
                  size="medium"
                  startIcon={<UploadIcon />}
                  disabled={loading}
                  onClick={handleInventoryTicketUploadButtonClick}
                  sx={{ width: { sm: 'initial', xs: '100%' }, mr: 1 }}
                >
                  {formatMessage({ id: 'inventory-area.upload-button' })}
                </Button>
              )}

              <IconButton size="small" color="inherit" onClick={handleOpenAdminMenu}>
                <ActionMenuIcon />
              </IconButton>

              <Menu
                anchorEl={adminMenuAnchorElement}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={adminMenuOpen}
                onClose={handleCloseAdminMenu}
              >
                {hasAdminRole && (
                  <MenuItem
                    disabled={loading}
                    onClick={event =>
                      handleCloseAdminMenu(event, handleImportEvaulationLinksButtonClick)
                    }
                  >
                    <ListItemIcon>
                      <UploadIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {formatMessage({ id: 'inventory-area.import-evaulation-links-button' })}
                    </ListItemText>
                  </MenuItem>
                )}

                <MenuItem
                  disabled={loading}
                  onClick={event => handleCloseAdminMenu(event, handleCSVExportButtonClick)}
                >
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {formatMessage({ id: 'inventory-area.export-button' })}
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item lg={7} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid container spacing={0}>
              <Grid item sm="auto" xs={12}>
                <TextField
                  name="search"
                  label={formatMessage({ id: 'inventory-area.search' })}
                  size="small"
                  value={searchInputValue}
                  onChange={handleSearchInputValueChange}
                  onKeyDown={handleSearchKeyPress}
                  disabled={loading || !!errorCode}
                  sx={{
                    width: { sm: '15.5rem', xs: '100%' },
                    mr: 2,
                    mb: { xs: 2 },
                    background: '#fff',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleSearch}
                          disabled={loading || !!errorCode}
                        >
                          <Tooltip title={formatMessage({ id: 'inventory-area.search' })}>
                            <SearchIcon />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item sm="auto" xs={12}>
                <TextField
                  name="sort-order"
                  label={formatMessage({ id: 'inventory-area.sort.order-field' })}
                  size="small"
                  select
                  value={orderFieldValue}
                  onChange={handleOrderFieldChange}
                  disabled={loading || !!errorCode}
                  sx={{
                    width: { sm: '15.5rem', xs: '100%' },
                    mb: { xs: 2 },
                    backgroundColor: '#fff',
                  }}
                >
                  <MenuItem value="LOCATION_CODE">
                    {formatMessage({ id: 'inventory-area.location-code' })}
                  </MenuItem>
                  <MenuItem value="ROOM_CODE">
                    {formatMessage({ id: 'inventory-area.room-code' })}
                  </MenuItem>
                  <MenuItem value="MANAGER_ID">
                    {formatMessage({ id: 'inventory-area.inventory-area-manager-id' })}
                  </MenuItem>
                </TextField>

                <IconButton onClick={handleOrderDirectionChange} disabled={loading || !!errorCode}>
                  <Tooltip title={formatMessage({ id: 'inventory-area.sort.order-direction' })}>
                    {orderDirectionValue === 'ASC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                  </Tooltip>
                </IconButton>

                <IconButton onClick={handleRefresh} disabled={loading || !!errorCode}>
                  <Tooltip title={formatMessage({ id: 'inventory-area.refresh' })}>
                    <SyncIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            lg={5}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: { sm: 'flex-start', md: 'flex-end' },
            }}
          >
            <StyledTablePagination
              component="div"
              count={inventoryAreas.count || -1}
              page={pageNumberValue}
              rowsPerPage={pageSizeValue}
              rowsPerPageOptions={[10, 25, 50]}
              onPageChange={handlePageNumberChange}
              onRowsPerPageChange={handlePageSizeChange}
              labelRowsPerPage={formatMessage({ id: 'inventory-area.pagination.page-size' })}
              labelDisplayedRows={({ from, to, count }) =>
                formatMessage(
                  { id: 'inventory-area.pagination.page-number' },
                  { from, to, count: count !== -1 ? count : '?' }
                )
              }
            />
          </Grid>
        </Grid>

        {loading &&
          [...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              animation="wave"
              sx={{ height: '3rem', mb: 2 }}
            />
          ))}

        {!loading && errorCode && (
          <EmptyTable message={formatMessage({ id: 'inventory-area.error' })}>
            <Button onClick={handleRefresh}>
              {formatMessage({ id: 'inventory-area.refresh' })}
            </Button>
          </EmptyTable>
        )}

        {!loading && !errorCode && inventoryAreas?.items?.length === 0 && (
          <EmptyTable message={formatMessage({ id: 'inventory-area.no-result' })} />
        )}

        {!loading &&
          inventoryAreas?.items?.length > 0 &&
          inventoryAreas.items.map(inventoryArea => (
            <StyledAccordion
              key={inventoryArea.id}
              header={{
                title: formatMessage(
                  {
                    id:
                      inventoryArea.inventoryTickets?.length > 1
                        ? 'inventory-area.inventory-area-name-plural'
                        : 'inventory-area.inventory-area-name',
                  },
                  {
                    locationCode: inventoryArea.locationCode,
                    roomCode: inventoryArea.roomCode,
                    inventoryAreaManagerId: inventoryArea.inventoryAreaManagerId,
                    inventoryTicketCount: inventoryArea.inventoryTickets?.length || 0,
                  }
                ),
                actions: [
                  {
                    label: 'inventory-area.action.generate-signature-file',
                    icon: <SyncIcon fontSize="small" />,
                    callback: () => generateInventoryAreaSignatureFile(inventoryArea.id),
                  },
                  userInfo?.sztsz === inventoryArea.inventoryAreaManagerId && {
                    label: 'inventory-area.action.upload-signature-file',
                    icon: <UploadIcon fontSize="small" />,
                    callback: () => handleInventoryAreaSignatureUploadButtonClick(inventoryArea.id),
                  },
                  inventoryArea.externalUrl && {
                    label: 'inventory-area.action.open-external-inventory-evaluation',
                    icon: <OpenInNewIcon fontSize="small" />,
                    callback: () => openExternalUrl(inventoryArea.externalUrl),
                  },
                ].filter(Boolean),
              }}
            >
              {inventoryArea.signatures?.length > 0 && (
                <InventoryAreaSignatureTable
                  inventoryAreaId={inventoryArea.id}
                  inventoryAreaSignatures={inventoryArea.signatures}
                  rowActions={[
                    {
                      label: 'inventory-area.inventory-area-signature.action.download',
                      icon: <DownloadIcon />,
                      callback: downloadInventoryAreaSignature,
                    },
                    hasAdminRole && {
                      label: 'inventory-area.inventory-area-signature.action.delete',
                      icon: <DeleteIcon />,
                      callback: deleteInventoryAreaSignature,
                    },
                  ].filter(Boolean)}
                />
              )}
              {inventoryArea.signatures?.length > 0 &&
                inventoryArea.inventoryTickets?.length > 0 && (
                  <Divider sx={{ borderColor: 'primary.main' }} />
                )}

              {inventoryArea.inventoryTickets?.length > 0 && (
                <InventoryTicketTable
                  inventoryAreaId={inventoryArea.id}
                  inventoryTickets={inventoryArea.inventoryTickets}
                  rowActions={[
                    {
                      label: 'inventory-area.inventory-ticket.action.download',
                      icon: <DownloadIcon />,
                      callback: downloadInventoryTicket,
                    },
                    hasAdminRole && {
                      label: 'inventory-area.inventory-ticket.action.delete',
                      icon: <DeleteIcon />,
                      callback: deleteInventoryTicket,
                    },
                  ].filter(Boolean)}
                />
              )}
            </StyledAccordion>
          ))}
      </PageContainer>
    </>
  );
};

export default InventoryAreaList;
