import { useIntl } from 'react-intl';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import TableBodyRow from '../TableBodyRow';
import TableBodyRowActionCell from '../TableBodyRowActionCell';

const InventoryTicketTable = ({ inventoryAreaId, inventoryTickets, rowActions }) => {
  const { formatMessage } = useIntl();

  const hasRowActions = rowActions?.length > 0;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.inventory-ticket-number' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.date-of-issue' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.external-room-code' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.organization-unit-code' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.assistant-id' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-ticket.storage-location-code' })}
            </TableCell>
            {hasRowActions && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody>
          {inventoryTickets.map(inventoryTicket => (
            <TableBodyRow key={inventoryTicket.inventoryTicketNumber}>
              <TableCell>{inventoryTicket.inventoryTicketNumber}</TableCell>
              <TableCell>{inventoryTicket.dateOfIssue}</TableCell>
              <TableCell>{inventoryTicket.externalRoomCode}</TableCell>
              <TableCell>{inventoryTicket.organizationUnitCode}</TableCell>
              <TableCell>{inventoryTicket.assistantId}</TableCell>
              <TableCell>{inventoryTicket.storageLocationCode}</TableCell>
              {hasRowActions && (
                <TableBodyRowActionCell>
                  {rowActions.map(rowAction => (
                    <Tooltip key={rowAction.label} title={formatMessage({ id: rowAction.label })}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          rowAction.callback(inventoryAreaId, inventoryTicket.inventoryTicketNumber)
                        }
                      >
                        {rowAction.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableBodyRowActionCell>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryTicketTable;
