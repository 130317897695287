import CircularProgress from '@mui/material/CircularProgress';

import PageContainer from '../PageContainer';
import PageTitle from '../PageTitle';

const pageContainerStyle = {
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ErrorAndInfoMessage = ({ backgroundColor, color, children }) => (
  <PageContainer backgroundColor={backgroundColor} sx={pageContainerStyle}>
    <PageTitle variant="h5" sx={{ color }}>
      {children}
    </PageTitle>
  </PageContainer>
);

const Loading = ({ backgroundColor, color }) => (
  <PageContainer backgroundColor={backgroundColor} sx={pageContainerStyle}>
    <CircularProgress sx={{ color }} />
  </PageContainer>
);

export { ErrorAndInfoMessage, Loading };
