import axios from 'axios';
import fileDownload from 'js-file-download';

const baseURL = '/api';
const filenameRegex = /filename\s*=\s*"(.+)"/i;

const api = axios.create({ baseURL });

const setAuthorizationHeader = accessToken => {
  api.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

const getLoggedInUserInfo = async () => {
  const { data } = await api.get('/whoami');
  return data;
};

const uploadInventoryTicket = async inventoryTicket => {
  const formData = new FormData();
  formData.append('file', inventoryTicket);

  const { data } = await api.post('/inventory-ticket/import', formData);

  return data;
};

const listInventoryTickets = async (
  searchTerm,
  pageNumber = 1,
  pageSize = 10,
  orderField = 'LOCATION_CODE',
  orderDirection = 'ASC'
) => {
  const { data } = await api.get('/inventory-area', {
    params: { pageNumber, pageSize, orderField, orderDirection, searchTerm },
  });

  return data;
};

const getInventoryTicketStatistics = async () => {
  const { data } = await api.get('/inventory-area/stats');
  return data;
};

const deleteInventoryTicket = inventoryTicketNumber =>
  api.delete(`/inventory-ticket/${inventoryTicketNumber}`);

const downloadFile = async path => {
  const response = await api.get(path, { responseType: 'blob' });

  const contentDisposition = response.headers['content-disposition'];
  const match = contentDisposition.match(filenameRegex);
  const filename = match[1];

  fileDownload(response.data, filename);
};

const downloadInventoryTicket = async inventoryTicketNumber =>
  downloadFile(`/inventory-ticket/${inventoryTicketNumber}`);

const downloadCSVExport = () => downloadFile('/inventory-area/export');

const generateInventoryAreaSignature = async inventoryAreaId =>
  downloadFile(`/inventory-area/${inventoryAreaId}/signature`);

const uploadInventoryAreaSignature = async (inventoryAreaId, inventoryAreaSignature) => {
  const formData = new FormData();
  formData.append('file', inventoryAreaSignature);

  const { data } = await api.post(`/inventory-area/${inventoryAreaId}/signature`, formData);

  return data;
};

const deleteInventoryAreaSignature = async (inventoryAreaId, inventoryAreaSignatureId) =>
  api.delete(`/inventory-area/${inventoryAreaId}/signature/${inventoryAreaSignatureId}`);

const downloadInventoryAreaSignature = async (inventoryAreaId, inventoryAreaSignatureId) =>
  downloadFile(`/inventory-area/${inventoryAreaId}/signature/${inventoryAreaSignatureId}`);

const uploadInventoryEvaluationLinks = async file => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post('/location/external-urls', formData);

  return data;
};

export default {
  setAuthorizationHeader,
  getLoggedInUserInfo,
  uploadInventoryTicket,
  listInventoryTickets,
  getInventoryTicketStatistics,
  deleteInventoryTicket,
  downloadInventoryTicket,
  downloadCSVExport,
  generateInventoryAreaSignature,
  uploadInventoryAreaSignature,
  deleteInventoryAreaSignature,
  downloadInventoryAreaSignature,
  uploadInventoryEvaluationLinks,
};
