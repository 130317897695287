import { useState } from 'react';

import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ActionMenuIcon from '@mui/icons-material/MoreVert';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
    border: '1px solid #c4c4c4',
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  backgroundImage: 'linear-gradient(to right, #35b0b5, #103d5e)',
  color: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(2),
  },
}));

const AccordionHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
}));

const AccordionTitle = styled(Typography)(() => ({
  flexGrow: 1,
  fontWeight: 'bold',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  borderTop: '1px solid #c4c4c4',
}));

const StyledAccordion = ({ header, children }) => {
  const { formatMessage } = useIntl();

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const menuOpen = Boolean(menuAnchorElement);

  const handleOpenMenu = event => {
    event.stopPropagation();
    setMenuAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = (event, callback) => {
    event.stopPropagation();

    if (typeof callback === 'function') {
      callback();
    }

    setMenuAnchorElement(null);
  };

  return (
    <Accordion>
      <AccordionSummary>
        <AccordionHeader>
          <AccordionTitle>{header?.title}</AccordionTitle>

          {header?.actions?.length > 0 && (
            <>
              <IconButton size="small" color="inherit" onClick={handleOpenMenu}>
                <ActionMenuIcon />
              </IconButton>

              <Menu
                anchorEl={menuAnchorElement}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={menuOpen}
                onClose={handleCloseMenu}
              >
                {header.actions.map(action => (
                  <MenuItem
                    key={action.label}
                    onClick={event => handleCloseMenu(event, action.callback)}
                  >
                    <ListItemIcon>{action.icon}</ListItemIcon>
                    <ListItemText>{formatMessage({ id: action.label })}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </AccordionHeader>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default StyledAccordion;
