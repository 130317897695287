import { useIntl } from 'react-intl';
import moment from 'moment';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import TableBodyRow from '../TableBodyRow';
import TableBodyRowActionCell from '../TableBodyRowActionCell';

const InventoryAreaSignatureTable = ({ inventoryAreaId, inventoryAreaSignatures, rowActions }) => {
  const { formatMessage } = useIntl();

  const hasRowActions = rowActions?.length > 0;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {formatMessage({ id: 'inventory-area.inventory-area-signature.creation-date' })}
            </TableCell>
            {hasRowActions && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody>
          {inventoryAreaSignatures.map(inventoryAreaSignature => (
            <TableBodyRow key={inventoryAreaSignature.id}>
              <TableCell>
                {moment(inventoryAreaSignature.creationDate).format('YYYY-MM-DD HH:mm:ss')}
              </TableCell>
              {hasRowActions && (
                <TableBodyRowActionCell>
                  {rowActions.map(rowAction => (
                    <Tooltip key={rowAction.label} title={formatMessage({ id: rowAction.label })}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          rowAction.callback(inventoryAreaId, inventoryAreaSignature.id)
                        }
                      >
                        {rowAction.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableBodyRowActionCell>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryAreaSignatureTable;
