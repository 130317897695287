import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const EmptyTable = ({ message, children }) => (
  <Paper sx={{ py: 15, textAlign: 'center' }}>
    <Typography sx={{ mb: 1 }}>{message}</Typography>
    {children}
  </Paper>
);

export default EmptyTable;
