import { styled } from '@mui/material/styles';

import MuiDialogContent from '@mui/material/DialogContent';

const DialogContent = styled(props => <MuiDialogContent {...props} />)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  paddingTop: 0,
}));

export default DialogContent;
