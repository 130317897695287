import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const PageContainer = ({ children, backgroundColor, sx }) => (
  <Box sx={{ backgroundColor: backgroundColor || 'primary.background' }}>
    <Container fixed>
      <Box sx={{ ...sx }}>{children}</Box>
    </Container>
  </Box>
);

export default PageContainer;
