import { styled } from '@mui/material/styles';

import TableRow from '@mui/material/TableRow';

const TableBodyRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default TableBodyRow;
