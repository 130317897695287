import { styled } from '@mui/material/styles';

import MuiDialogActions from '@mui/material/DialogActions';

const DialogActions = styled(props => <MuiDialogActions {...props} />)(({ theme, justify }) => ({
  justifyContent: justify === 'true' ? 'space-between' : 'flex-end',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  paddingTop: 0,
}));

export default DialogActions;
