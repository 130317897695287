import { useState, useEffect } from 'react';

import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useIntl } from 'react-intl';

import { inventoryResource } from '../../rest';

import UserInfoContext from '../../contexts/UserInfoContext';

import Header from '../../components/Header';
import InventoryAreaList from '../InventoryAreaList';
import { ErrorAndInfoMessage, Loading } from '../../components/LoadingAndErrorMessage';

const Main = () => {
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }

    if (auth.isAuthenticated) {
      inventoryResource.setAuthorizationHeader(auth.user.access_token);
      setInitialized(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.activeNavigator === 'signoutRedirect') {
    return <ErrorAndInfoMessage>{formatMessage({ id: 'common.logout' })}</ErrorAndInfoMessage>;
  }

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.error) {
    return <ErrorAndInfoMessage v>{auth.error.message}</ErrorAndInfoMessage>;
  }

  if (!auth.isAuthenticated || !initialized) {
    return <ErrorAndInfoMessage>{formatMessage({ id: 'common.initialize' })}</ErrorAndInfoMessage>;
  }

  return (
    <UserInfoContext>
      <Header />
      <InventoryAreaList />
    </UserInfoContext>
  );
};

export default Main;
