import { createContext, useContext, useState, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { inventoryResource } from '../../rest';

import { ErrorAndInfoMessage, Loading } from '../../components/LoadingAndErrorMessage';

const UserInfoContext = createContext();

export const useUserInfo = () => useContext(UserInfoContext);

const UserInfoProvider = ({ children }) => {
  const { formatMessage } = useIntl();

  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [userInfoFetchError, setUserInfoFetchError] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const fetchedUserInfo = await inventoryResource.getLoggedInUserInfo();
        setUserInfo(fetchedUserInfo);
      } catch (error) {
        setUserInfoFetchError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const contextValue = useMemo(() => userInfo, [userInfo]);

  if (loading) {
    return <Loading />;
  }

  if (userInfoFetchError) {
    return <ErrorAndInfoMessage>{formatMessage({ id: 'user-info.error' })}</ErrorAndInfoMessage>;
  }

  return <UserInfoContext.Provider value={contextValue}>{children}</UserInfoContext.Provider>;
};

export default UserInfoProvider;
