import { useState, useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import UploadIcon from '@mui/icons-material/Upload';

import DialogTitle from '../Dialog/DialogTitle';
import DialogContent from '../Dialog/DialogContent';
import DialogActions from '../Dialog/DialogActions';
import DialogResponse from '../Dialog/DialogResponse';

import { inventoryResource } from '../../rest';

const DropzoneWrapper = styled(props => <Box {...props} />)(({ theme }) => ({
  minWidth: '22rem',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  background: theme.palette.primary.background,
  border: `2px dashed ${theme.palette.primary.light}`,
  borderRadius: theme.spacing(0.5),
}));

const FileUploadDialog = ({ uploadDialog, onClose }) => {
  const { formatMessage } = useIntl();

  const [filesUnderUpload, setFilesUnderUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [atLeastOneSuccessfulUpload, setAtLeastOneSuccessfulUpload] = useState(false);

  const handleClose = (event, reason) => {
    setFilesUnderUpload([]);
    setLoading(false);
    setAtLeastOneSuccessfulUpload(false);
    onClose(event, reason, atLeastOneSuccessfulUpload);
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      if (!acceptedFiles?.length) {
        return;
      }

      setLoading(true);

      const filesToUpload = acceptedFiles.map(file => ({ name: file.name, loading: true }));
      setFilesUnderUpload(filesToUpload);

      acceptedFiles.forEach(async file => {
        const previousFilesUnderUpload = [...filesToUpload];

        const index = previousFilesUnderUpload.findIndex(
          fileUnderUpload => fileUnderUpload.name === file.name
        );

        try {
          if (uploadDialog.type === 'inventory-ticket') {
            await inventoryResource.uploadInventoryTicket(file);
          } else if (uploadDialog.type === 'signature') {
            await inventoryResource.uploadInventoryAreaSignature(uploadDialog.data, file);
          } else if (uploadDialog.type === 'evaulation-links') {
            await inventoryResource.uploadInventoryEvaluationLinks(file);
          }

          setAtLeastOneSuccessfulUpload(true);
        } catch (error) {
          previousFilesUnderUpload[index].error = error?.response?.status || 500;
        } finally {
          previousFilesUnderUpload[index].loading = false;
        }

        setFilesUnderUpload(previousFilesUnderUpload);
      });

      setLoading(false);
    },
    [uploadDialog]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      uploadDialog.type === 'evaulation-links'
        ? { 'text/csv': ['.csv'] }
        : { 'application/pdf': ['.pdf'] },
    multiple: uploadDialog.type === 'inventory-ticket',
    noKeyboard: true,
    disabled: loading,
  });

  return (
    <Dialog open={uploadDialog.open} onClose={onClose} disableEscapeKeyDown>
      {uploadDialog.type && (
        <DialogTitle>
          {formatMessage({ id: `inventory-area.upload.title.${uploadDialog.type}` })}
        </DialogTitle>
      )}

      <DialogContent>
        <Box {...getRootProps()}>
          <DropzoneWrapper>
            <input {...getInputProps()} />

            <Box sx={{ textAlign: 'center' }}>
              <UploadIcon fontSize="large" sx={{ color: 'primary.light' }} />

              <Typography sx={{ color: 'primary.light' }}>
                {formatMessage({ id: 'inventory-area.upload.dropzone' })}
              </Typography>
            </Box>
          </DropzoneWrapper>
        </Box>

        <Box sx={{ mt: 1 }}>
          {filesUnderUpload.map((file, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
              <Typography sx={{ mr: 2, fontSize: '0.875rem' }}>{file.name}</Typography>
              {file.loading && <LinearProgress sx={{ flex: 1 }} />}

              {!file.loading && file.error && (
                <DialogResponse
                  sx={{ flex: 1, justifyContent: 'flex-end' }}
                  type="error"
                  message={formatMessage({
                    id: `inventory-area.upload.error.${file.error}.${uploadDialog.type}`,
                  })}
                />
              )}

              {!file.loading && !file.error && (
                <DialogResponse
                  sx={{ flex: 1, justifyContent: 'flex-end' }}
                  type="success"
                  message={formatMessage({
                    id: `inventory-area.upload.success.${uploadDialog.type}`,
                  })}
                />
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={(event, reason) => handleClose(event, reason)} disabled={loading}>
          {formatMessage({ id: 'inventory-area.upload.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
