import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';

const PageTitle = styled(props => (
  <Typography variant={props.variant || 'h4'} component="h2" {...props} />
))(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export default PageTitle;
