import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DialogTitle from '../Dialog/DialogTitle';
import DialogContent from '../Dialog/DialogContent';
import DialogActions from '../Dialog/DialogActions';
import DialogResponse from '../Dialog/DialogResponse';

const ConfirmationDialog = ({ open, message, loading, error, onConfirm, onClose }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown>
      <DialogTitle>{formatMessage({ id: 'inventory-area.confirmation.title' })}</DialogTitle>

      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>

      <DialogActions justify={(!!(loading || error)).toString()}>
        {loading && (
          <DialogResponse
            type="loading"
            message={formatMessage({ id: 'inventory-area.confirmation.loading' })}
          />
        )}

        {!loading && error && (
          <DialogResponse
            type="error"
            message={formatMessage({ id: 'inventory-area.confirmation.error' })}
          />
        )}

        <Box>
          <Button onClick={onConfirm}>
            {formatMessage({ id: 'inventory-area.confirmation.confirm' })}
          </Button>
          <Button onClick={onClose}>
            {formatMessage({ id: 'inventory-area.confirmation.cancel' })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
