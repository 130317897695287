import { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';

const ErrorMessage = styled(props => <Typography {...props} />)(({ type }) => {
  const style = {
    fontSize: '0.875rem',
    lineHeight: 1,
  };

  if (type === 'error') {
    style.color = red[800];
  }

  if (type === 'success') {
    style.color = green[700];
  }

  return style;
});

const DialogResponse = ({ type, message, sx }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'error':
        return <ErrorIcon fontSize="small" sx={{ mr: 0.5, color: red[800] }} />;
      case 'success':
        return <SuccessIcon fontSize="small" sx={{ mr: 0.5, color: green[700] }} />;
      default:
        return <CircularProgress size="1rem" sx={{ mr: 0.5 }} />;
    }
  }, [type]);

  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'center' }}>
      {icon}
      <ErrorMessage type={type}>{message}</ErrorMessage>
    </Box>
  );
};

export default DialogResponse;
