import { useEffect, useState } from 'react';

import { AuthProvider } from 'react-oidc-context';
import axios from 'axios';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import LocaleProvider from '../../contexts/LocaleContext';

import Main from '../Main';
import { ErrorAndInfoMessage, Loading } from '../../components/LoadingAndErrorMessage';

const theme = createTheme({
  palette: {
    primary: {
      background: '#ecf7f9',
      main: '#30a0a9',
      light: '#30a0a9',
      dark: '#217185',
      contrastText: '#fff',
    },
  },
});

const App = () => {
  const [loading, setLoading] = useState(false);
  const [configurationError, setConfigurationError] = useState(false);
  const [OIDCConfiguration, setOIDCConfiguration] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setConfigurationError(false);

        const { data } = await axios.get('application.json');

        setOIDCConfiguration({
          authority: data.auth.url,
          client_id: data.auth.clientId,
          scope: data.auth.scope,
          redirect_uri: data.applicationUrl,
          post_logout_redirect_uri: data.applicationUrl,
          onSigninCallback: () => {
            window.history.replaceState({}, document.title, window.location.pathname);
          },
        });
      } catch (error) {
        setConfigurationError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Loading
        backgroundColor={theme.palette.primary.background}
        color={theme.palette.primary.main}
      />
    );
  }

  if (configurationError) {
    return (
      <ErrorAndInfoMessage
        backgroundColor={theme.palette.primary.background}
        color={theme.palette.primary.main}
      >
        Configuration Error
      </ErrorAndInfoMessage>
    );
  }

  if (OIDCConfiguration) {
    return (
      <AuthProvider {...OIDCConfiguration}>
        <CssBaseline />

        <ThemeProvider theme={theme}>
          <LocaleProvider>
            <Main />
          </LocaleProvider>
        </ThemeProvider>
      </AuthProvider>
    );
  }

  return null;
};

export default App;
